import { StreamProfile } from "../api/streams";

export interface AppState {
  isUserLogged: boolean;
  isStateInitialized: boolean;
  liveStreams: StreamProfile[]
}

export const initialState: AppState = {
  isUserLogged: false,
  isStateInitialized: false,
  liveStreams: []
};
