const acTermsUrl = 'https://actionclip.video/terms/'
const acPrivacyUrl = 'https://actionclip.video/privacy/'

var validatePassword = /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9!@#$%^&*a-zA-Z]{8,}/;

const footerNavsList = [
    // {
    //     name: 'Pricing',
    //     link: 'https://actionclip.video/#subscription'
    // },
    // {
    //     name: 'Tutorials',
    //     link: 'https://actionclip.video/stream/howto/'
    // },
    // {
    //     name: 'FAQ',
    //     link: 'https://actionclip.video/stream/faqs/'
    // },
    {
        name: 'Contact Us',
        link: 'https://actionclip.video/#contacts'
    }
]


export enum CountryGroup {
    NA = '',
    USA = 'USA States',
    usaRegions = 'USA Regions',
    CA = 'Provinces of Canada',
}

const statesList = [
    {
        label: CountryGroup.USA,
        options: [
            {
                value: 'AL',
                label: 'Alabama',
            },
            {
                value: 'AK',
                label: 'Alaska',
            },
            {
                value: 'AZ',
                label: 'Arizona',
            },
            {
                value: 'AR',
                label: 'Arkansas',
            },
            {
                value: 'CA',
                label: 'California',
            },
            {
                value: 'CO',
                label: 'Colorado',
            },
            {
                value: 'CT',
                label: 'Connecticut',
            },
            {
                value: 'DC',
                label: 'District of Columbia',
            },
            {
                value: 'DE',
                label: 'Delaware',
            },
            {
                value: 'FL',
                label: 'Florida',
            },
            {
                value: 'GA',
                label: 'Georgia',
            },
            {
                value: 'HI',
                label: 'Hawaii',
            },
            {
                value: 'ID',
                label: 'Idaho',
            },
            {
                value: 'IL',
                label: 'Illinois',
            },
            {
                value: 'IN',
                label: 'Indiana',
            },
            {
                value: 'IA',
                label: 'Iowa',
            },
            {
                value: 'KS',
                label: 'Kansas',
            },
            {
                value: 'KY',
                label: 'Kentucky',
            },
            {
                value: 'LA',
                label: 'Louisiana',
            },
            {
                value: 'ME',
                label: 'Maine',
            },
            {
                value: 'MD',
                label: 'Maryland',
            },
            {
                value: 'MA',
                label: 'Massachusetts',
            },
            {
                value: 'MI',
                label: 'Michigan',
            },
            {
                value: 'MN',
                label: 'Minnesota',
            },
            {
                value: 'MS',
                label: 'Mississippi',
            },
            {
                value: 'MO',
                label: 'Missouri',
            },
            {
                value: 'MT',
                label: 'Montana',
            },
            {
                value: 'NE',
                label: 'Nebraska',
            },
            {
                value: 'NV',
                label: 'Nevada',
            },
            {
                value: 'NH',
                label: 'New Hampshire',
            },
            {
                value: 'NJ',
                label: 'New Jersey',
            },
            {
                value: 'NM',
                label: 'New Mexico',
            },
            {
                value: 'NY',
                label: 'New York',
            },
            {
                value: 'NC',
                label: 'North Carolina',
            },
            {
                value: 'ND',
                label: 'North Dakota',
            },
            {
                value: 'OH',
                label: 'Ohio',
            },
            {
                value: 'OK',
                label: 'Oklahoma',
            },
            {
                value: 'OR',
                label: 'Oregon',
            },
            {
                value: 'PA',
                label: 'Pennsylvania',
            },
            {
                value: 'PR',
                label: 'Puerto Rico',
            },
            {
                value: 'RI',
                label: 'Rhode Island',
            },
            {
                value: 'SC',
                label: 'South Carolina',
            },
            {
                value: 'SD',
                label: 'South Dakota',
            },
            {
                value: 'TN',
                label: 'Tennessee',
            },
            {
                value: 'TX',
                label: 'Texas',
            },
            {
                value: 'UT',
                label: 'Utah',
            },
            {
                value: 'VT',
                label: 'Vermont',
            },
            {
                value: 'VA',
                label: 'Virginia',
            },
            {
                value: 'WA',
                label: 'Washington',
            },
            {
                value: 'WV',
                label: 'West Virginia',
            },
            {
                value: 'WI',
                label: 'Wisconsin',
            },
            {
                value: 'WY',
                label: 'Wyoming',
            },
        ],
    },
    // {
    //     label: CountryGroup.CA,
    //     options: [
    //         {
    //             value: 'AB',
    //             label: 'Alberta',
    //         },
    //         {
    //             value: 'BC',
    //             label: 'British Columbia',
    //         },
    //         {
    //             value: 'LB',
    //             label: 'Labrador',
    //         },
    //         {
    //             value: 'MB',
    //             label: 'Manitoba',
    //         },
    //         {
    //             value: 'NB',
    //             label: 'New Brunswick',
    //         },
    //         {
    //             value: 'NF',
    //             label: 'Newfoundland',
    //         },
    //         {
    //             value: 'NS',
    //             label: 'Nova Scotia',
    //         },
    //         {
    //             value: 'NU',
    //             label: 'Nunavut',
    //         },
    //         {
    //             value: 'NW',
    //             label: 'North West Terr.',
    //         },
    //         {
    //             value: 'ON',
    //             label: 'Ontario',
    //         },
    //         {
    //             value: 'PE',
    //             label: 'Prince Edward Is.',
    //         },
    //         {
    //             value: 'QC',
    //             label: 'Quebec',
    //         },
    //         {
    //             value: 'SK',
    //             label: 'Saskatchewan',
    //         },
    //         {
    //             value: 'YU',
    //             label: 'Yukon',
    //         },
    //     ],
    // },
];

export { acTermsUrl, acPrivacyUrl, validatePassword, footerNavsList, statesList }