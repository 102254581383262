import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { StateProvider } from './state/StateContext';

import './index.less';
import SpectatorApp from './pages/SpectatorApp';
import reportWebVitals from './reportWebVitals';

import './api/config';


ReactDOM.render(
  <StateProvider>
    <BrowserRouter>
      <SpectatorApp />
    </BrowserRouter>
  </StateProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
