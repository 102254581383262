import { BrowserRouter as Router, Outlet } from 'react-router-dom';
import { Col, Layout, Row } from 'antd';

import './skeleton.scss';

import { footerNavsList } from '../constants/data';
import HeaderComponent from '../components/header/HeaderComponent';

const { Footer, Content } = Layout;

export default function Skeleton() {
  return (
    <Layout className="private-layout">

    <HeaderComponent />

    <Content style={{ minHeight: 'calc(100vh - 170px)', display: 'flex' }}>
      <Outlet />
    </Content>

    <Footer className='footer-container'>
      <span>© Action Clip 2022. All Rights Reserved.</span>
      <div className="nav-block">
        <Row gutter={[16, { xs: 16, sm: 0 }]}>
          {footerNavsList.map(item => (
            <Col className="gutter-row" key={item.name}>
              <a className='nav-item' href={item.link} target="_blank" rel="noopener noreferrer"> {item.name}</a>
            </Col>))
          }
        </Row>
      </div>
    </Footer>
  </Layout >
  )
}