import { useEffect } from 'react';
import Router from '../routes';
import { useAppStateValue } from '../state/StateContext';

export default function SpectatorApp() {
  const [, dispatch] = useAppStateValue();

  useEffect(() => {
    dispatch({ type: 'updateState', value: { isUserLogged: false, isStateInitialized: true } });
  }, []);

  return <Router />;

}
