import { Link } from 'react-router-dom';
import { Layout } from 'antd';

import ActionClip from '../../assets/LogoBlack.png';

import './header.scss';
import { PATH_PAGE } from '../../routes/paths';

const { Header } = Layout;


export default function HeaderComponent() {
  return (
    <Header className='header'>

      <div className='header-menu'>
        {/* <Link to={PATH_PAGE.root}> */}
        <div className="">
          <img src={ActionClip} alt="logo" style={{ width: 157 }} />
        </div>
        {/* </Link> */}
      </div>
    </Header>
  )
}
