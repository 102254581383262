const {
  REACT_APP_ENV: environment = 'dev',
  REACT_APP_API_URL: urlApiServer = '',
  REACT_APP_STRIPE_PUB_KEY: stripeKey = '',
  REACT_APP_STREAMING_TIMEOUT: streamingTimeout = 20,
  REACT_APP_EVENT_ID: eventId = ''
} = process.env;

const config = {
  urlApiServer,
  environment,
  stripeKey,
  gameScoreVideoOverlay: true,
  eventId,
  streamingTimeout
};

console.log('Environment', environment);

export default config;
