import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import Skeleton from '../pages/Skeleton';

// guards
import GuestGuard from '../guards/GuestGuard';

// config
import { PATH_PAGE } from './paths';
import { Loader } from '../components/Loader';


export const PATH_INIT = PATH_PAGE.root;


// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <Skeleton />
        </GuestGuard>
      ),
      children: [
        // { path: '', element: <div>Hello</div> },
        { path: 'watch/streamer/:streamerId', element: <Channel /> },
        { path: 'watch/:ticketHash', element: <ChannelTicketHash /> },
        { path: '/events/:eventId/watch/teams/:teamCode', element: <ChannelTeamCode /> },
        {
          path: 'purchase-tickets',
          children: [
            { element: <Payment />, index: true },
            { path: 'successful', element: <PaymentSuccessful /> },
          ]
        },

      ],
    },

    // Error Routes
    {
      path: 'error',
      element: (
        <ErrorLayout />
      ),
      children: [
        { element: <Navigate to={'404'} replace />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '500', element: <Page500 /> },
      ],
    },

    { path: '*', element: <Navigate to="/error/404" replace /> },
  ]);
}


const Channel = Loadable(lazy(() => import('../pages/public/channel/Channel')));
const ChannelTicketHash = Loadable(lazy(() => import('../pages/public/channel/ChannelTicketHash')));
const ChannelTeamCode = Loadable(lazy(() => import('../pages/public/channel/ChannelTeamCode')));
const Payment = Loadable(lazy(() => import('../pages/public/payment/Payment')));
const PaymentSuccessful = Loadable(lazy(() => import('../pages/public/payment/PaymentSuccessful')));
const Page404 = Loadable(lazy(() => import('../pages/errors/Page404')));
const Page403 = Loadable(lazy(() => import('../pages/errors/Page403')));
const Page500 = Loadable(lazy(() => import('../pages/errors/Page500')));
const ErrorLayout = Loadable(lazy(() => import('../pages/errors/ErrorLayout')));