// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_PAGE = '';
const ROOTS_ERROR = '/error';

// ----------------------------------------------------------------------



export const PATH_PAGE = {
  root: ROOTS_PAGE,
  watchHall: (id: string) => path(ROOTS_PAGE, id),
  watchByTicketHash: (ticketHash: string) => path(ROOTS_PAGE, `/watch/${ticketHash}`),
  watchByTeamCode: (teamCode: string) => path(ROOTS_PAGE, `/watch/team/${teamCode}`),
  purchaseTickets: {
    root: path(ROOTS_PAGE, '/purchase-tickets'),
    purchaseTicketsSuccessful: path(ROOTS_PAGE, '/purchase-tickets/successful'),
  },
};

export const PATH_ERROR = {
  root: ROOTS_ERROR,
  404: path(ROOTS_ERROR, '/404'),
  403: path(ROOTS_ERROR, '/403'),
  500: path(ROOTS_ERROR, '/500'),
};

