import { ReactNode } from 'react';
// import { Navigate } from 'react-router-dom';

// hooks
import { useAppStateValue } from '../state/StateContext';
// routes
// import { PATH_DASHBOARD } from '../routes/paths';
import { Loader } from '../components/Loader';



// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const [{ isUserLogged, isStateInitialized }] = useAppStateValue();

  // if (isUserLogged) {
  //   return <Navigate to={PATH_DASHBOARD.root} />;
  // }

  if (!isStateInitialized) {
    return <Loader />;
  }

  return <>{children}</>;
}
