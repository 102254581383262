import { AppState } from './AppState';

type StateActionType = 'updateState' | 'initState';

export interface StateAction {
  type: StateActionType;
  value?: { [Property in keyof AppState]?: AppState[Property] };
}

export function Reducer(state: AppState, action: StateAction): AppState {
  switch (action.type) {
    case 'updateState':
      return {
        ...state,
        ...action.value,
      };

    case 'initState':
      return {
        ...state,
        isStateInitialized: true,
      };

    default:
      return state;
  }
}
